import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import Img from "gatsby-image";

export const AboutPageTemplate = ({
  title,
  description,
  image,
  content,
  contentComponent
}) => {
  const PageContent = contentComponent || Content;

  return (
    <section className="section has-no-padding-top">
      <div className="container content">
        <div className="columns">
          <div className="column is-2 is-hidden-mobile" />
          <div className="column is-8">
            <div className="section content">
              <div className="introduction-blurb">
                <Img
                  fixed={image.childImageSharp.fixed}
                  alt="Picture of Guedis speaking while holding a microphone"
                  className="about-image"
                />
                <div>
                  <h2 className="has-text-primary">{title}</h2>
                  <p className="">{description}</p>
                </div>
              </div>
              <PageContent
                id="about-page-markdown"
                className="content"
                content={content}
              />
            </div>
          </div>
          <div className="column is-2 is-hidden-mobile" />
        </div>
      </div>
    </section>
  );
};

AboutPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func
};

const AboutPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <AboutPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        image={post.frontmatter.image}
        description={post.frontmatter.description}
        content={post.html}
      />
    </Layout>
  );
};

AboutPage.propTypes = {
  data: PropTypes.object.isRequired
};

export default AboutPage;

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        image {
          childImageSharp {
            fixed(width: 100, height: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        title
        description
      }
    }
  }
`;
